import React, { useContext } from "react";
import { SettingsContext, CategoriesContext } from "../App";
import "../css/Header.css";
import Label from "./Label";
import LangSelectorHeader from "./LangSelectorHeader";
import QrIcon from "../icons/QrIcon";
import HamburgerIcon from "../icons/HamburgerIcon";
import BackIcon from "../icons/BackIcon";
import TicketIcon from "../icons/TicketIcon";


function Header({ menuVisible, setMenuVisible }) {
	const { settings, course, setCourse, category, setCategory, setPromoPopup, setQrPopupVisible } =
		useContext(SettingsContext);
	const { categories } = useContext(CategoriesContext);

	const toggleMenu = () => {
		console.log("toggleMenu");
		if (menuVisible) setMenuVisible(false);
		else setMenuVisible(true);
	};

	const handleSetCategory = () => {
		console.log("handleSetCategory");
		if (categories && categories.length > 1 && !category) setCourse(false);
		setCategory(false);
	};

	const goToHome = () => {
		if (settings.selfordering.hide_home == 1) {
			if (categories.length > 0) {
				setCourse(categories[0]);
				console.log(categories[0].categories.length);
				if (categories[0].categories.length > 0) {
					console.log(categories[0].categories[0]);
					if (categories[0].categories[0]) setCategory(categories[0].categories[0]);
				}
			}
		} else {
			if (categories && categories.length > 1) setCourse(false);
			setCategory(false);
		}
	};

	return (
		<>
			<div className="header">
				<div className="header-left">
					<a id="menu_button" onClick={toggleMenu}>
						<HamburgerIcon></HamburgerIcon>
					</a>
					{settings.selfordering.categories_menu_type == 1 && (
						<>
							{((categories && categories.length > 1 && course) || category) && (
								<button onClick={() => handleSetCategory()} className="back">
									<BackIcon></BackIcon>
								</button>
							)}
						</>
					)}
				</div>
				<button onClick={goToHome} className="logo">
					{settings.selfordering.logo && (
						<img src={settings.selfordering.logo} alt="Logo" />
					)}
				</button>
				<div className="header-right">
					{settings.selfordering.show_promo == 1 && (
						<button className="promo-button" onClick={() => setPromoPopup(true)}>
							<TicketIcon></TicketIcon>
							<Label number={110} />
						</button>
					)}
					<button className="qr-button" onClick={() => setQrPopupVisible(true)}>
						<QrIcon />
					</button>
					<LangSelectorHeader />
				</div>
			</div>
		</>
	);
}

export default Header;
