import { useContext, useEffect, useState } from "react";
import { SettingsContext, ShopContext, UserContext } from "../../App";
import Api from "../api/api";
import Moment from "react-moment";
import "moment-timezone";
import "moment/locale/it";
import OrderSheet from "./OrderSheet";
import Label from "../Label";
import { getStatusLabel } from "../functions/functions";
import SearchIcon from "../../icons/SearchIcon";

function Orders() {
	const { setLoading } = useContext(SettingsContext);
	const { user } = useContext(UserContext);
	const { brand } = useContext(ShopContext);
	const [orders, setOrders] = useState([]);
	const [order, setOrder] = useState(false);

	useEffect(() => {
		loadOrders();
	}, []);

	const loadOrders = async () => {
		setLoading(true);
		let request = {
			src: [{ name: "customer_id", value: user.id, compare: "equal" }],
			orderby: "delivery_date DESC",
		};
		if (brand) {
			request.src.push({ name: "brand_id", value: brand.id, compare: "equal" });
		}
		const res = await Api.post("/orders/list/", request);
		setLoading(false);
		console.log(res);
		setOrders(res.rows);
	};

	return (
		<>
			{!order ? (
				<div className="verticalContainer">
					<div className="title">
						<Label number={76} />
					</div>
					<div className="scrollContent">
						{orders.length == 0 ? (
							<div className="text-center">Nessun ordine trovato</div>
						) : (
							orders.map((order, i) => (
								<div key={i}>
									<div className="d-flex justify-content-between align-items-center">
										<div>
											<div className="bold">Ordine N°{order.id}</div>
											<div className="small">
												<Moment format={"DD/MM/YYYY LT"} locale="it">
													{order.delivery_date}
												</Moment>
											</div>
										</div>
										<div>
											<Label number={getStatusLabel(order.status)} />
										</div>
										<div className="small">
											<button
												className="basic-button"
												onClick={() => setOrder(order)}
											>
												<SearchIcon></SearchIcon>
											</button>
										</div>
									</div>
									<hr />
								</div>
							))
						)}
						{ }
					</div>
				</div>
			) : (
				<OrderSheet order={order} setOrder={setOrder} />
			)}
		</>
	);
}

export default Orders;
