import { useContext, useState } from "react";
import { SettingsContext } from "../../App";
import CloseButton from "../CloseButton";
import Fidelity from "./Fidelity";
import Orders from "./Orders";
import Profile from "./Profile";
import Qr from "./Qr";
import Label from "../Label";
import QrIcon from "../../icons/QrIcon";

function Dashboard({ setUserPopupVisible }) {
	const { settings, setSettings } = useContext(SettingsContext);
	const [page, setPage] = useState("profile");

	return (
		<>
			<CloseButton onClick={() => setUserPopupVisible(false)} />
			<div className="dashboard">
				<button onClick={() => setPage("profile")}>
					<Label number={32} />
				</button>
				<button onClick={() => setPage("orders")}>
					<Label number={76} />
				</button>
				{settings.fidelity?.active == 1 && (
					<button onClick={() => setPage("fidelity")}>
						<Label number={95} />
					</button>
				)}
				{settings.customers.show_user_qr == 1 && (
					<button onClick={() => setPage("user_qr")}>
						<QrIcon></QrIcon>
					</button>
				)}
			</div>
			{page == "profile" && <Profile setUserPopupVisible={setUserPopupVisible} />}
			{page == "orders" && <Orders />}
			{settings.fidelity?.active == 1 && page == "fidelity" && <Fidelity />}
			{settings.customers.show_user_qr == 1 && page == "user_qr" && <Qr />}
		</>
	);
}

export default Dashboard;
